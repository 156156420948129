<template>
   <ion-page>
        <ion-tabs>
          <ion-router-outlet ></ion-router-outlet>      
          <ion-tab-bar v-if="showFrankTabs()" slot="bottom">
            <ion-tab-button tab="home" href="/home" @click="menuNavigation('/home')">
              <ion-icon aria-hidden="true"  :src="customButton(1)" />
              <ion-label>Home</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="elections" href="/elections" @click="menuNavigation('/elections')">
              <ion-icon aria-hidden="true"  :src="customButton(2)" />
              <ion-label>CoA</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="groups" href="/groups" @click="menuNavigation('/groups')">
              <ion-icon aria-hidden="true"  :icon="peopleCircle" />
              <ion-label>{{ $t("tabs.groups") }}</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="featured" href="/featured" @click="menuNavigation('/featured')">
              <ion-icon aria-hidden="true"  :icon="idCard" />
              <ion-label>{{ $t("tabs.featured") }}</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="menu" href="/menu" @click="menuNavigation('/menu')">
              <ion-icon aria-hidden="true"  :icon="menu" />
              <ion-label>{{ $t("tabs.more") }}</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
          <ion-tab-bar v-if="showTabs()" slot="bottom">
            <ion-tab-button tab="home" href="/home" @click="menuNavigation('/home')">
              <ion-icon aria-hidden="true"  :icon="home" />
              <ion-label>{{ $t("tabs.home") }}</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="explore" href="/explore" @click="menuNavigation('/explore')">
              <ion-icon aria-hidden="true"  :icon="globe" />
              <ion-label>{{ $t("tabs.explore") }}</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="featured" href="/featured" @click="menuNavigation('/featured')">
              <ion-icon aria-hidden="true"  :icon="idCard" />
              <ion-label>{{ $t("tabs.featured") }}</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="groups" href="/groups" @click="menuNavigation('/groups')">
              <ion-icon aria-hidden="true"  :icon="peopleCircle" />
              <ion-label>{{ $t("tabs.groups") }}</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="menu" href="/menu" @click="menuNavigation('/menu')">
              <ion-icon aria-hidden="true"  :icon="menu" />
              <ion-label>{{ $t("tabs.more") }}</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </ion-tabs>
   </ion-page>
</template>

<script>
import {
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  modalController,
  alertController,
  IonRouterOutlet,
  isPlatform
} from "@ionic/vue";
import {
  menu,
  globe,
  idCard,
  home,
  search,
  notifications,
  chatbubble,
  bookmarks,
  peopleCircle
} from "ionicons/icons";

import { defineComponent } from 'vue'
import getEnv from "@/utils/env";
import SignupModal from '@/components/SignupModal.vue'
import { PushNotifications } from '@capacitor/push-notifications';
import { Browser } from '@capacitor/browser';

export default defineComponent({
  name: "TabsPage",
  components: {
    IonPage,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonRouterOutlet
  },
  setup() {
    const beforeTabChange = () => {
      // do something before tab change
      //console.log("before change");
    };
    const afterTabChange = () => {
      // do something after tab change
      //console.log("after change");
    };
    return {
      menu,
      globe,
      beforeTabChange,
      afterTabChange,
      idCard,
      home,
      getEnv,
      search,
      notifications,
      chatbubble,
      bookmarks,
      peopleCircle
    };
  },
  data() {
    return {
      darkModeInit: "",
      profilepath: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET') + '/profile/',
      pollData: null
    };
  },
  head() {
    return {
      title: this.$store.state.header.title,
      meta: this.$store.state.header.meta,  
      script: [ 
        { src: 'https://' + getEnv('FATHOM_URL') + '/script.js', 'data-spa': 'auto', 'data-site': getEnv('FATHOM_ID') },
      ],
    }
  },
  mounted() {
    if (this.$store.state.helpers.darkMode == "true") {
      document.body.classList.add("dark");
      this.darkModeInit = "true";
    } else {
      document.body.classList.remove("dark");
      this.darkModeInit = "false";
    }    
    if (this.$store.state.auth.token!='') {
      clearInterval(this.pollData); //clear out the old one
      this.beginPolling();
    }
  },
  methods: {
    menuNavigation(url) {
      if (this.$store.state.auth.token=='') {
        //console.log("show login popup");
        this.presentLoginAlertConfirm();
        //this.$router.push('/login');
      } else {
        // check if you tapped the home button again
        //console.log(url, 'menu navigation commands');
        var keyFlag = "";
        if (this.$route.path == url) {
          if (this.$route.path == "/saved") {
            keyFlag = "saved";
          } else if (this.$route.path == "/home") {
            keyFlag = "home";
          } else if (this.$route.path == "/explore") {
            keyFlag = "explore";
          } else if (this.$route.path == "/featured") {
            keyFlag = "featured";
          } else if (this.$route.path == "/elections") {
            keyFlag = "elections";
          } else if (this.$route.path == "/groups") {
            keyFlag = "groups";
          }/* else if (this.$route.path == "/myprofile") {
            keyFlag = "myprofile";
          }*/
        }

        if (keyFlag != "") {
          //console.log('going to the same place');
          if (keyFlag == "saved") {
            this.$store.commit("pikstream/setFavoriteRequest", null);
          } else if (keyFlag == "home") {
            this.$store.commit("pikstream/setFollowRequest", null);
            this.$store.dispatch("pikstream/getStream", {
              streamName: 'home',
              filter: '',
              imageid: '',
              storyid: '',
              total_list: this.$store.state.pikstream.totalList.follow,
              last_id: this.$store.state.pikstream.lastId.follow,
              userid: ''
            });

            /* if(getEnv('APP_NAME') == 'FrankSocial'){
              this.$store.commit("pikstream/setRecommendRequest", null);
              this.$store.dispatch("pikstream/getStream", {
                streamName: 'featured',
                filter: '',
                imageid: '',
                storyid: '',
                total_list: this.$store.state.pikstream.totalList.recommend,
                last_id: this.$store.state.pikstream.lastId.recommend,
                userid: ''
              });
            }else{
              this.$store.commit("pikstream/setFollowRequest", null);
              this.$store.dispatch("pikstream/getStream", {
                streamName: 'home',
                filter: '',
                imageid: '',
                storyid: '',
                total_list: this.$store.state.pikstream.totalList.follow,
                last_id: this.$store.state.pikstream.lastId.follow,
                userid: ''
              });
            }*/
          } else if (keyFlag == "elections") {
            this.$store.commit("elections/setRequest", []);
            this.$store.dispatch("elections/getData", {
                page: 0,
                type: '',
                current_page: 0,
                total_list: 30,
            });
          } else if (keyFlag == "explore") {
            this.$store.commit("explore/setRequest", []);
            this.$store.dispatch("explore/getData", {
              page: 0,
              type: null,
            });
          } else if (keyFlag == "featured") {
            this.$store.commit("featured/setRequest", []);
            this.$store.dispatch("featured/getData", {
              page: 0,
              type: null,
              current_page: 0,
              total_list: 30,
            });
          } else if (keyFlag == "groups") {
            this.$store.commit("groups/setUserGroups", null);
            this.$store.commit("groups/setFeaturedGroups", null);
            this.$store.commit("groups/setSectionGroups", null); 
          } 

          //this.$store.commit("helpers/setReloadStream", "Y");
        }
        let headerValue = this.getHeaderName(url);
        this.$store.dispatch("header/general", {
          page: headerValue
        });
        
        if(this.$store.state.auth.token != ''){
          this.$store.dispatch("helpers/getNotifyBadges");
        }
        // if(nav){
        //this.$router.replace({ path: url });
        //}
        
      }
    },
    getHeaderName(url){
        let title = "";
        if (url == "/saved") {
          title = this.$t("header.saved");
        } else if (url == "/home") {
          title = this.$t("header.home");
        } else if (url == "/explore") {
          title = this.$t("header.explore");
        } else if (url == "/featured") {
          title = this.$t("header.featured");
        } else if (url == "/friends/2/0" || url=='/followers') {
          title = this.$t("header.followers");
        } else if (url == "/friends/1/0" || url=='/following') {
          title = this.$t("header.following");
        } else if (url == "/options") {
          title = this.$t("header.options");
        } else if (url == "/notifications") {
          title = this.$t("header.notifications");
        } else if (url == "/user-login") {
          title = this.$t("header.login");
        } else if (url == "/signup") {
          title = this.$t("header.signup");
        } 
        return title;
    },
    buttonColor(index) {
      if (index == 1 && this.$route.path == "/home") {
        return "primary";
      } else if (index == 3 && this.$route.path == "/profile/" + this.myProfileId) {
        return "primary";
      } else if (index == 4 && this.$route.path == "/friends/2/0") {
        return "primary";
      } else if (index == 5 && this.$route.path == "/saved") {
        return "primary";
      } else if (
        index == 6 &&
        (this.$route.path == "/featured" ||
          this.$route.path == "/premium")
      ) {
        return "primary";
      } else if (index == 7 && this.$route.path == "/explore") {
        return "primary";
      } else if (index == 8 && this.$route.path == "/groups") {
        return "primary";
      } else if (index == 9 && this.$route.path == "/options") {
        return "primary";
      } else if (index == 10 && this.$route.path == "/notifications") {
        return "primary";
      } else if (index == 11 && this.$route.path == "/menu") {
        return "primary";
      } 
    },
    customButton(index) {
      //console.log('getting custom frank button');
      var source = "";
      if (index == 1) {
        if (this.$route.path == "/home") {
          source = "/assets/frank-color.svg";
        } else {
          source = "/assets/frank.svg";
        }
      }
      if (index == 2) {
        if (this.$route.path == "/elections") {
          source = "/assets/coa-color.svg";
        } else {
          source = "/assets/coa.svg";
        }
      }
      return source;
    },
    async presentLoginAlertConfirm() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: this.$t('alerthead.login'),
        message: this.$t('alertmessage.login', {app: getEnv("APP_NAME")}),
        buttons: [
            {
              text: this.$t('header.signup'),
              cssClass: 'primary',
              handler: () => {
                this.signup();
              },
            },
            {
              text: this.$t('header.login'),
              handler: () => {
                this.login();
              },
            },
            {
              text: this.$t('buttons.cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            
          ],
      });
      return alert.present();
    },
    signup() {
      if(getEnv('APP_NAME') == 'FrankSocial'){
        this.fsbnConnect('signup');
      }else{
        this.presentSignup('signup');
      }
    },
    login() {
      if(getEnv('APP_NAME') == 'FrankSocial'){
        this.fsbnConnect('login');
      }else{
        this.presentSignup('login');
      }
      
    },
    async openLink(link_url) {
      if (link_url != '') {
        if (isPlatform('hybrid') || isPlatform('electron')) {
          await Browser.open({ url: link_url, presentationStyle: 'popover' });
        }else{
          window.open(link_url, '_blank');
        }
      }
    },
    async fsbnConnect(type) {
      let redirect = 'https://' + window.location.hostname + '/idauth';
      if (isPlatform('hybrid') || isPlatform('electron')) {
        redirect = 'https://' + getEnv('SHARE_HOSTNAME') + '/idauth';
      }
      const client_id = 'ceb0f61a-976b-4b29-a59f-55ec3f600d6f';
      let url_add = '';
      if(type=="signup") url_add = '&goto=signup';
      this.openLink('https://frankspeech.com/OAuth/Start?redirect_uri='+redirect+'&client_id='+client_id+'&response_type=code&state='+type+url_add);
    },
    async presentSignup(type, button){
      let title = this.$t('header.login');
        if(type=="signup"){
          title = this.$t('header.signup');
        }
        const modal = await modalController
        .create({
          component: SignupModal,
          cssClass: 'modal-custom-class',
          componentProps: {
            title: title,
            loginType: type,
            source: 'link'
          }
        })
        await modal.present();

        const { role } = await modal.onDidDismiss();
        if(role=='loggedin'){
          if(button=='buy'){
            this.openBuyModal();
          }
        }
    },
    async beginPolling() {
      //console.log('begin poll for messages');
      let pollTimeOut = 20000;
      setTimeout(() => {
        // note: this is a hack to fix an issue in ionic auto-grow
        this.count = this.count + 1;
      }, 100);

      //NOTE: turn off and test separately
      this.pollData = setInterval(() => {
        this.pollNotifications();
      }, pollTimeOut);
    },
    async pollNotifications() {
      //console.log('notifications poll: ' + new Date());
      if (this.$store.state.auth.token != '') {
        this.$store.dispatch("helpers/getNotifyBadges");
        if(isPlatform('hybrid') && getEnv('APP_NAME')!='FrankSocial'){ 
          await PushNotifications.removeAllDeliveredNotifications();
        }
      }
      // grab an endpoint that gives you indication whether you have any new messages anywhere then put a badge on the messages and notifcations buttons
      /* let response = await this.$store.dispatch("messages/threadpoll");
      if (response['error'] != undefined) {
        console.log('Error: ' + response['error']);
      }*/
    },
    showFrankTabs(){
      let showMenu = false;
      if(getEnv('APP_NAME') == 'FrankSocial' && this.$store.state.auth.token != ''){
        showMenu = true;
      }
      //console.log(showMenu, 'show menu');
      return showMenu;
    },
    showTabs(){
      let showMenu = false;
      if(getEnv('APP_NAME') != 'FrankSocial' && this.$store.state.auth.token != ''){
        showMenu = true;
      }
      return showMenu;
    }
  },
  computed: {
    myProfilePic(){
      return this.$store.state.profile.pic;
    },
    myProfileId(){
      return this.$store.state.profile.id;
    },
  }
});
</script>
<style scoped>
ion-thumbnail {
  --size: 40px;
  margin-left: 20px;
}

.title-text{
    font-size: 0.7em;
}

@media only screen and (min-width: 599px) {
  .title-text{
    font-size: 1.0em;
  }
}
</style>
