<template>
  <div class="form-box">
    <div class="banner-bg">
      <img class="logo-login" src="/assets/login/welcome_logo.png">
    </div>
    <div v-if="signedup == false">
      <ion-list>
        <ion-item>
          <ion-input type="username" autocomplete="username" name="username"
            :placeholder="$t('placeholder.createusername')" v-model="username" required="true"></ion-input>
        </ion-item>
        <div v-show="errorUsername != ''" class="error-block"><ion-note color="light">{{
              errorUsername }}</ion-note></div>
        <ion-item>
          <ion-input :disabled="$store.state.auth.email != ''" type="email" autocomplete="email" name="email" :placeholder="$t('placeholder.createemail')"
            v-model="email" required="true"></ion-input>
          <div v-show="errorEmail != ''" class="error-block"><ion-note color="light">{{
              errorEmail }}</ion-note></div>
        </ion-item>
        <ion-item v-if="$store.state.auth.fsbnToken == ''">
          <ion-input type="password" autocomplete="password" name="password" v-model="password"
            :placeholder="$t('placeholder.createpassword')" required="true"></ion-input>
        </ion-item>
      </ion-list>
      <div style="background-color:rgb(var(--dashboard-background-color-rgb));" class="ion-padding-horizontal">
      <input class="tel-input" v-if="getEnv('APP_NAME') == 'FrankSocial'" v-maska="bindedObject"
            :data-maska="showMask()" type="tel" ref="phone" autocomplete="phone" name="phone"
            :placeholder="$t('placeholder.createphone')" v-model="phone" required="true">
          <div v-show="errorPhone != ''" class="error-block"><ion-note color="light">{{
              errorPhone }}</ion-note></div>
        </div>
      <ion-button expand="block" v-on:click="this.signup()">{{ $t('buttons.next') }}</ion-button>
      <div v-show="error != ''" class="error-block"><ion-note color="light">{{ error }}</ion-note></div>
    </div>
    <div v-else>
      <div class="photo-upload" @click="openPicker">
        <ion-grid style="margin:20px">
          <ion-row class="ion-text-center">
            <ion-col class="ion-no-padding">
              <ion-icon v-if="this.profilePic == ''" style="font-size:32px;" :icon="camera"></ion-icon><br />
              <img class="post-profile-pic" v-if="profilePic != '' && profilePic != undefined"
                :src="profilepath + profilePic">
              {{ $t('buttons.addphoto') }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <input v-if="signedup" ref="fileInput" id="file-input" type="file" name="file-input" style="display: none;"
        :onchange="openCropModal" />
      <ion-list>
        <ion-item>
        <ion-input v-if="signedup" type="given-name" name="fullname" :placeholder="$t('placeholder.createfullname')"
          v-model="fullname" required="false"></ion-input>
        </ion-item>
      </ion-list>
      <ion-button v-if="signedup" expand="block" v-on:click="finish()">{{
        $t('buttons.finish') }}</ion-button>
    </div>
    <div>
      <ion-grid v-show="!isPlatform('hybrid') && !isPlatform('electron')">
        <ion-row class="ion-text-center">
          <ion-col v-show="getEnv('ITUNES_URL') != '#'" class="ion-padding-vertical">
            <a :href="getEnv('ITUNES_URL')" target="_blank">
              <img src="/assets/login/apple_app_store.png" />
            </a>
          </ion-col>
          <ion-col v-show="getEnv('PLAYSTORE_URL') != '#'" class="ion-padding-vertical">
            <a :href="getEnv('PLAYSTORE_URL')" target="_blank">
              <img alt='Get it on Google Play' src='/assets/login/en_badge_web_generic.png' />
            </a>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-show="isPlatform('hybrid') || isPlatform('electron')">
        <ion-row class="ion-text-center small-link">
          <ion-col>
            <a :href="getEnv('PRIVACY_PAGE')" target="_blank">
              {{ $t('navlinks.privacy') }}
            </a>
          </ion-col>
          <ion-col v-if="getEnv('MOBILE_TERMS_PAGE')!=''">
            <a :href="getEnv('MOBILE_TERMS_PAGE')" target="_blank">
              {{ $t('navlinks.mobileterms') }}
            </a>
          </ion-col>
          <ion-col>
            <a :href="getEnv('TERMS_PAGE')" target="_blank">
              {{ $t('navlinks.terms') }}
            </a>
          </ion-col>
        </ion-row>
        <ion-row class="ion-text-center">
          <ion-col></ion-col>
          <ion-col>
            <ion-select style="color:white; font-size: 0.8em;" justify="space-between" :label="$t('options.dislang')"
              :aria-label="$t('options.dislang')" @ionChange="changeLocale($event.detail.value)"
              :value="this.$root.$i18n.locale" interface="popover">
              <ion-select-option value="en">English</ion-select-option>
              <ion-select-option value="pt">Portuguese</ion-select-option>
              <ion-select-option value="es">Spanish</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col></ion-col>
        </ion-row>

      </ion-grid>
    </div>
  </div>
</template>
  
<script>
import { IonCol, IonGrid, IonRow, IonButton, IonInput, IonNote, loadingController, IonIcon, isPlatform, modalController, IonSelect, IonSelectOption, IonItem, IonList } from '@ionic/vue';
import { camera, arrowBack } from 'ionicons/icons';
import '../theme/signup.css';
import 'vue-advanced-cropper/dist/style.css';
import getEnv from '@/utils/env'
import { vMaska } from 'maska'
import { defineAsyncComponent } from 'vue';
const CropperModal = defineAsyncComponent(() => import('@/components/options/CropperModal.vue'))
import intlTelInput from 'intl-tel-input';

export default {
  name: "SignupContainer",
  props: {
    source: { type: String, default: '' },
    dismissFlag: { Boolean, default: false }
  },
  directives: { maska: vMaska },
  emits: ['update:dismissFlag'],
  components: { IonCol, IonGrid, IonRow, IonButton, IonInput, IonNote, IonIcon, IonSelect, IonSelectOption, IonItem, IonList },
  data() {
    return {
      username: '',
      password: '',
      email: '',
      phone: '',
      error: '',
      errorUsername: '',
      errorEmail: '',
      errorPhone: '',
      fullname: '',
      photo: '',
      loadingIndicator: '',
      signedup: false,
      uploadphoto: false,
      img: '',
      profilepath: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET') + '/profile/',
      bindedObject: {
        masked: "",
        unmasked: "",
        completed: false
      },
      iti: null
    }
  },
  setup() {
    return { camera, getEnv, isPlatform, arrowBack }
  },
  mounted() {
    this.$store.dispatch("header/general", {
      page: this.$t('header.signup')
    });
    if(getEnv('APP_NAME') == 'FrankSocial'){
      const input = this.$refs['phone'];
      this.iti = intlTelInput(input, {
        preferredCountries: ["us", "gb", "ca", "br", "mx", "pt", "es"]
      });
      this.username = this.$store.state.auth.username;
      this.email = this.$store.state.auth.email;
      this.fullname = this.$store.state.auth.fullname;
      this.phone = this.$store.state.auth.phone;
    }
  },
  methods: {
    changeLocale(select) {
      this.$root.$i18n.locale = select;
      return;
    },
    async signup() {
      var validated = this.validateFields();
      if (validated != "") {
        this.error = validated;
      } else {
        await this.presentLoading();
        let formattedPhone = '';
        if (this.phone != '') {
          let country = this.iti.getSelectedCountryData();
          formattedPhone = this.phone.replace(/[^\w]/g, "");
          formattedPhone = "+" + country.dialCode + " " + formattedPhone;
        }
        let response = await this.$store.dispatch("auth/signup", {
          username: this.username,
          password: this.password,
          email: this.email,
          phone: formattedPhone
        });
        if (response) {
          if (response['error'] != null) {
            this.loadingIndicator.dismiss();
            //console.log('Error: ' + response['error']);
            //console.log(response);
            this.error = response['error'];
            this.errorUsername = response['errorUsername'];
            this.errorEmail = response['errorEmail'];
            this.errorPhone = response['errorPhone'];
          } else {
            let profileResponse = await this.$store.dispatch("profile/info");
            if (profileResponse) {
              this.loadingIndicator.dismiss();
              this.$root.$i18n.locale = this.$store.state.profile.locale;
              this.signedup = true;
            }
            /* let loginResponse = await this.$store.dispatch("auth/login", {
              username: this.username,
              password: this.password,
            });
            if (loginResponse) {
              if (loginResponse['error'] != null) {
                this.loadingIndicator.dismiss();
                console.log('Error: ' + loginResponse['error']);
                console.log(loginResponse);
                if (loginResponse['error'] == "Request failed with status code 401") {
                  this.error = this.$t('errors.incorrectlogin');
                } else {
                  this.error = loginResponse['error'];
                }
              } else {
                let profileResponse = await this.$store.dispatch("profile/info");
                if (profileResponse) {
                  this.loadingIndicator.dismiss();
                  this.$root.$i18n.locale = this.$store.state.profile.locale;
                  this.signedup = true;
                }
              }
            } else {
              // you can add condition here
              this.loadingIndicator.dismiss();
              this.error = this.$t('errors.unknown');
            }*/
          }

        } else {
          // you can add condition here
          this.loadingIndicator.dismiss();
          this.error = this.$t('errors.unknown');
        }
      }
    },
    async openCropModal() {
      if (this.$refs['fileInput'] != undefined) {
        console.log(this.$refs['fileInput'].value);
        const modal = await modalController
          .create({
            component: CropperModal,
            cssClass: 'modal-custom-class',
            componentProps: {
              image: this.$refs['fileInput'],
              type: 'profile'
            }
          })
        return await modal.present();
      }
    },
    async finish() {
      await this.presentLoading();
      let response = await this.$store.dispatch("profile/updateMe", {
        fullname: this.fullname,
      });

      if (response) {
        this.loadingIndicator.dismiss();
        if (response['error'] != null) {
          console.log('Error: ' + response['error']);
        } else {
          this.finalizeRegister();
        }
      } else {
        this.loadingIndicator.dismiss();
        console.log('no response ' + response);
        //this.finalizeRegister();
      }

    },
    async finalizeRegister() {
      this.$emit('update:dismissFlag', true);
      if (this.source == 'home') {
        if (this.$store.state.helpers.loginRedirect != '') {
          var loginRedirect = this.$store.state.helpers.loginRedirect;
          this.$store.commit('helpers/setRedirectLogin', '');
          await this.$router.replace(loginRedirect);
          //window.location = loginRedirect;
        } else {
          //window.location = '/welcome';
          await this.$router.replace('/welcome');
        }
      }
    },
    change({ coordinates, canvas }) {
      console.log(coordinates, canvas);
    },
    openPicker() {
      this.$refs['fileInput'].click();
    },
    openCrop() {
      console.log(this.$refs['fileInput'].value);
      if (this.$refs['fileInput'].value != null) {
        console.log('true');
        this.uploadphoto = true;
        var input = this.$refs['fileInput'];
        if (input.files && input.files[0]) {
          // create a new FileReader to read this image and convert to base64 format
          var reader = new FileReader();
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
            // Read image as base64 and set to imageData
            this.img = e.target.result;
          };
          // Start the reader job - read file as a data url (base64 format)
          reader.readAsDataURL(input.files[0]);
        }
      }
    },
    validateFields() {
      var validateFields = "";
      if (!this.validateEmail(this.email)) {
        validateFields = validateFields + this.$t('errors.validemail');
      }

      /* if (getEnv('APP_NAME') == 'FrankSocial') {
        if (!this.validatePhone(this.phone)) {
          validateFields = validateFields + this.$t('errors.validphone');
        }
      } */

      /* if (!this.curseFilter(this.email)){
        validateFields = validateFields + " Your email address has a word not allowed on this network";
      }
 
      if (!this.curseFilter(this.username)){
        validateFields = validateFields + " Your username has a word not allowed on this network";
      } */

      if (this.username.length < 4 || (this.password.length < 4 && this.$store.state.auth.fsbn=='')) {
        validateFields = validateFields + this.$t('errors.userpasslength');
      }

      if (this.username.search(" ") != -1) {
        validateFields = validateFields + this.$t('errors.userspace');
      }

      if (this.username.search("@") != -1) {
        validateFields = validateFields + this.$t('errors.useratsymbol');
      }

      if (this.username.search("#") != -1) {
        validateFields = validateFields + this.$t('errors.userhashsymbol');
      }

      var usernameLower = this.username.toLowerCase();
      if (usernameLower.search('pikmobile') != -1) {
        validateFields = validateFields + this.$t('errors.userprotected', { invalidname: 'pikmobile' });
      }

      if (usernameLower.search('frankspeech') != -1) {
        validateFields = validateFields + this.$t('errors.userprotected', { invalidname: 'frankspeech' });
      }

      return validateFields;

    },
    validateEmail() {
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (emailRegex.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },
    showMask() {
      let mask = "(###) ###-####";
      if (this.iti != null) {
        let country = this.iti.getSelectedCountryData();
        if (country.dialCode != '1') {
          mask = "";
        }
      }
      return mask
    },
    validatePhone(phonenumber) {
      var cleanNumber = phonenumber.replace('(', '');

      cleanNumber = cleanNumber.replace(')', '');
      cleanNumber = cleanNumber.replace('-', '');
      cleanNumber = cleanNumber.replace(' ', '');
      console.log(cleanNumber);
      var phoneno = /^\d{10}$/;
      if (cleanNumber.match(phoneno)) {
        return true;
      } else {
        return false;
      }
    },
    async presentLoading() {
      this.loadingIndicator = await loadingController
        .create({
          cssClass: 'loading-class',
          message: this.$t('loadmessage.loggingin'),
        });
      await this.loadingIndicator.present();
    }
  },
  computed: {
    profilePic() {
      return this.$store.state.profile.pic;
    }
  }
};
</script>
<style>
.iti {
  width: 100% !important;
  margin-bottom: 10px;
  z-index: 9999;
  border: 0;
  margin-bottom: 10px;
  border-radius: 15px !important;
}

.signup-button {
  --background: rgba(80, 80, 80, 1.0);
  margin-top: 20px;
}

.photo-upload {
  background: white;
  color: rgba(60, 60, 60, 1.0);
  border-radius: 5px;
  width: 100px;
  font-size: 0.8em;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.error-label {
  padding-top: 25px;
  color: lightgray;
}

.loading-class {
  --background: #222;
  --spinner-color: #fff;

  color: #fff;
}
</style>
  