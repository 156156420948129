<template>
  
    <ion-toolbar v-if="this.$store.state.auth.token == ''" color="light">
      <ion-buttons slot="start">
        <ion-back-button></ion-back-button>
      </ion-buttons>
      <ion-thumbnail slot="start" style="cursor:pointer;" @click="menuNavigation('/home')">
        <img src="/assets/icon/favicon.png" />
      </ion-thumbnail>
      <ion-title style="cursor:pointer;" @click="menuNavigation('/home')">
        {{ getEnv("APP_NAME") }}
      </ion-title>
    </ion-toolbar>
    <ion-toolbar v-else color="light">
      <ion-buttons slot="start">
        <ion-back-button></ion-back-button>
        <ion-button v-show="!isPlatform('iphone') && !isPlatform('android')" class="showMobile"
          @click="toggleMenu()"><ion-icon slot="icon-only" :icon="menu"></ion-icon></ion-button>
      </ion-buttons>
      <ion-title>
        {{ title }}
      </ion-title>
      <ion-buttons slot="secondary">
        <ion-button id="notifications" v-if="this.$store.state.auth.token" @click="goToNotifications()">
          <ion-icon slot="icon-only" :icon="notificationsOutline"></ion-icon>
          <ion-badge color="danger" class="notify-badge-top-bar">
            {{ this.$store.state.helpers.badges }}
          </ion-badge>
        </ion-button>
        <ion-button v-if="this.$store.state.auth.token" @click="goToMessages()">
          <ion-icon slot="icon-only" :icon="chatbubbleOutline" />
          <ion-badge v-if="this.$store.state.helpers.messageBadges > 0" color="danger" class="notify-badge-top-bar">
            {{ this.$store.state.helpers.messageBadges }}
          </ion-badge>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="primary">
        <ion-button v-if="this.$store.state.helpers.searchToggle == '' && this.$store.state.auth.token"
          @click="searchApp()">
          <ion-icon slot="icon-only" :icon="searchOutline"></ion-icon>
        </ion-button>
        <ion-button v-if="getEnv('APP_NAME') == 'FrankSocial' && this.$store.state.auth.token"
          href="https://frankspeech.com" target="_blank">
          <img src="/assets/fspeech.png" />
        </ion-button>
        <ion-button v-if="this.$store.state.auth.token" @click="goToProfile()" style="width: 40px">
          <img v-if="myProfilePic != '' &&
            myProfilePic != undefined
            " :src="profilepath + myProfilePic" style="border-radius: 25px" />
          <img v-if="myProfilePic == '' ||
            myProfilePic == undefined
            " src="/assets/spyguy.png" style="border-radius: 25px" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
</template>
<script>
import {
  IonToolbar,
  IonTitle,
  IonIcon,
  modalController,
  alertController,
  IonButton,
  IonButtons,
  IonBadge,
  isPlatform,
  IonBackButton,
  IonThumbnail
} from "@ionic/vue";
import {
  menu,
  people,
  star,
  home,
  searchOutline,
  notificationsOutline,
  arrowBack,
  chatbubbleOutline
} from "ionicons/icons";

import { defineComponent } from 'vue'
import getEnv from "@/utils/env";
import { PushNotifications } from '@capacitor/push-notifications';
import SignupModal from '@/components/SignupModal.vue'
import { Browser } from '@capacitor/browser';

export default defineComponent({
  name: "SplitPane",
  props: {
    title: { String, default: '' }
  },
  components: {
    IonToolbar,
    IonTitle,
    IonIcon,
    IonButton,
    IonButtons,
    IonBadge,
    IonBackButton,
    IonThumbnail
  },
  setup() {
    return {
      menu,
      people,
      star,
      home,
      getEnv,
      searchOutline,
      notificationsOutline,
      arrowBack,
      isPlatform,
      chatbubbleOutline
    };
  },
  data() {
    return {
      profilepath: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET') + '/profile/'
    };
  },
  head() {
    return {
      title: this.$store.state.header.title,
      meta: this.$store.state.header.meta,
      script: [
        { src: 'https://' + getEnv('FATHOM_URL') + '/script.js', 'data-spa': 'auto', 'data-site': getEnv('FATHOM_ID') },
      ],
    }
  },
  methods: {
    menuNavigation(url) {
      if (!this.$store.state.auth.token) {
        //console.log("show login popup");
        this.presentLoginAlertConfirm();
      } else {
        // check if you tapped the home button again
        if (this.$route.path == url) {
          if (getEnv('INFLUENCER_FEED') == 1) {
              this.$store.commit("pikstream/setRecommendRequest", null);
            } else {
              this.$store.commit("pikstream/setFollowRequest", null);
            }
        }
      }
      let headerValue = this.getHeaderName(url);
      this.$store.dispatch("header/general", {
        page: headerValue
      });

      if (this.$store.state.auth.token != '') {
        this.$store.dispatch("helpers/getNotifyBadges");
      }

    },
    getHeaderName(url) {
      let title = "";
      if (url == "/saved") {
        title = this.$t("header.saved");
      } else if (url == "/home") {
        title = this.$t("header.home");
      } else if (url == "/explore") {
        title = this.$t("header.explore");
      } else if (url == "/featured") {
        title = this.$t("header.featured");
      } else if (url == "/feed") {
        title = this.$t("header.feed");
      } else if (url == "/friends/2/0" || url == '/followers') {
        title = this.$t("header.followers");
      } else if (url == "/friends/1/0" || url == '/following') {
        title = this.$t("header.following");
      } else if (url == "/options") {
        title = this.$t("header.options");
      } else if (url == "/notifications") {
        title = this.$t("header.notifications");
      } else if (url == "/user-login") {
        title = this.$t("header.login");
      } else if (url == "/signup") {
        title = this.$t("header.signup");
      }
      return title;
    },
    displayBackButton() {
      var isSubPage = true;
      if ((this.$route.path == "/featured" ||
        this.$route.path == "/home") ||
        this.$route.path == "/explore" ||
        this.$route.path == "/searchall" ||
        this.$route.path == "/friends/2/0" ||
        this.$route.path == "/profile/" + this.myProfileId ||
        this.$route.path == "/saved" ||
        this.$route.path == "/feed" ||
        this.$route.path == "/login" ||
        this.$route.path == "/404" ||
        this.$route.path == "/change-pass" ||
        this.$route.path == "/messages" ||
        this.$route.path == "/menu") {
        isSubPage = false;
      }
      //console.log(this.$route.name, 'name');
      /* if(this.$route.name == "story"){
        this.backButtonText = 'Story';
      }else if(this.$route.name == "profile"){
        this.backButtonText = 'Profile';
      } */
      return isSubPage;
    },
    async goBack() {
      this.$router.go(-1);
    },
    async presentLoginAlertConfirm() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: this.$t('alerthead.login'),
        message: this.$t('alertmessage.login', { app: getEnv("APP_NAME") }),
        buttons: [
          {
            text: this.$t('header.signup'),
            cssClass: 'primary',
            handler: () => {
              this.signup();
            },
          },
          {
            text: this.$t('header.login'),
            handler: () => {
              this.login();
            },
          },
          {
            text: this.$t('buttons.cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },

        ],
      });
      return alert.present();
    },
    signup() {
      if(getEnv('APP_NAME') == 'FrankSocial'){
        this.fsbnConnect('signup');
      }else{
        this.presentSignup('signup');
      }
    },
    login() {
      if(getEnv('APP_NAME') == 'FrankSocial'){
        this.fsbnConnect('login');
      }else{
        this.presentSignup('login');
      }
      
    },
    async openLink(link_url) {
      if (link_url != '') {
        if (isPlatform('hybrid') || isPlatform('electron')) {
          await Browser.open({ url: link_url, presentationStyle: 'popover' });
        }else{
          window.open(link_url, '_blank');
        }
      }
    },
    async fsbnConnect(type) {
      let redirect = 'https://' + window.location.hostname + '/idauth';
      if (isPlatform('hybrid') || isPlatform('electron')) {
        redirect = 'https://' + getEnv('SHARE_HOSTNAME') + '/idauth';
      }
      const client_id = 'ceb0f61a-976b-4b29-a59f-55ec3f600d6f';
      let url_add = '';
      if(type=="signup") url_add = '&goto=signup';
      this.openLink('https://frankspeech.com/OAuth/Start?redirect_uri='+redirect+'&client_id='+client_id+'&response_type=code&state='+type+url_add);
    },
    async presentSignup(type, button) {
      let title = this.$t('header.login');
      if (type == "signup") {
        title = this.$t('header.signup');
      }
      const modal = await modalController
        .create({
          component: SignupModal,
          cssClass: 'modal-custom-class',
          componentProps: {
            title: title,
            loginType: type,
            source: 'link'
          }
        })
      await modal.present();

      const { role } = await modal.onDidDismiss();
      if (role == 'loggedin') {
        if (button == 'buy') {
          this.openBuyModal();
        }
      }
    },
    async registerPushes() {
      if (getEnv('APP_NAME') == 'FrankSocial') {
        let token = '';
        let id = '';
        // if you uncomment this it will ask for them to optin to pushes
        // we are going to let OneSignal handle this part
        /* if(window.plugins.OneSignal.User.pushSubscription.optedIn==false){
          window.plugins.OneSignal.User.pushSubscription.optIn();
        } */
        if(await window.plugins.OneSignal.User.pushSubscription != null){
          token = await window.plugins.OneSignal.User.pushSubscription.token;
          id = await window.plugins.OneSignal.User.pushSubscription.id;
        }
        if (id != '' && token != '') {
          let response = await this.$store.dispatch("helpers/notifyToken", {
            token_id: id,
            identifier: token,
            aws: null,
            android: null
          });
          if (response) {
            if (response['error'] != null) {
              console.log('Error: ' + response['error']);
            } else {
              //console.log(response, 'got it');
            }
          } else {
            console.log('Unkown error');
          }
        }
        // Ionic 5 Capacitor may need to use (window as any).plugins.OneSignal
        /* window.plugins.OneSignal.getDeviceState(async (stateChanges) => {
          console.log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges));
        }); */
      } else {
        this.registerNotifications();
        this.addListeners();
        /* const getDeliveredNotifications = async () => {
          const notificationList = await PushNotifications.getDeliveredNotifications();
          console.log('delivered notifications', notificationList);
        }
        getDeliveredNotifications(); */
      }
    },
    async registerNotifications() {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    },
    async addListeners() {
      await PushNotifications.addListener('registration', async (token) => {
        console.info('Registration token: ', token.value);
        let android = '';
        if (isPlatform('android')) {
          android = 'YES';
        }
        let response = await this.$store.dispatch("helpers/notifyToken", {
          token_id: token.value,
          identifier: '',
          aws: true,
          android: android
        });
        if (response) {
          if (response['error'] != null) {
            console.log('Error: ' + response['error']);
          } else {
            console.log(response, 'got it');
          }
        } else {
          console.log('Unkown error');
        }
      });

      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
      });

      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
      });
    },
    goToProfile() {
      if (isPlatform('hybrid')) { // only register pushes on phone devices
        this.registerPushes();
      }
      this.$router.replace({ name: 'profile', params: { id: this.myProfileId } });
    },
    goToNotifications() {
      if (isPlatform('hybrid')) { // only register pushes on phone devices
        this.registerPushes();
      }

      if(this.$route.path == "/notifications"){
        this.$store.commit("profile/setNotifications", null);
        this.$store.commit("profile/setRequests", null);
        this.$store.dispatch("profile/notifications", {
          totalList: 50,
          currentPage: 0,
          update: false,
          type: 'notifications'
        });
        this.$store.dispatch("profile/requests");
      }
      this.$router.replace('/notifications');
    },
    goToMessages() {
      this.$store.commit("messages/setThreads", []);
      this.$store.dispatch("messages/allThreads");
      this.$store.dispatch("header/general", {
        page: this.$t('header.messages')
      });
      this.$router.replace('/messages');
    },
    async searchApp() {
      this.$store.dispatch("header/general", {
        page: this.$t('header.search')
      });
      this.$router.replace('/searchall');
      /* if(this.$store.state.explore.likeProfileData.length==0){
        let top1 = await this.$store.dispatch("explore/topLikeProfiles");
        console.log(top1, 'return top likes');
        //let top2 = await this.$store.dispatch("explore/topCommentProfiles");
        if(top1){
          this.$router.replace('/searchall');
        }
      }else{
        console.log('cached');
        this.$router.replace('/searchall');
      }*/
    },
    toggleMenu() {
      let setToggle = true;
      if (this.$store.state.helpers.toggleMenu == true) {
        setToggle = false;
      }
      this.$store.commit("helpers/setToggleMenu", setToggle);
    },
  },
  computed: {
    myProfilePic() {
      return this.$store.state.profile.pic;
    },
    myProfileId() {
      return this.$store.state.profile.id;
    },
  }
});
</script>
<style scoped>
ion-thumbnail {
  --size: 40px;
  margin-left: 20px;
}

.title-text {
  font-size: 0.7em;
}

@media only screen and (min-width: 599px) {
  .title-text {
    font-size: 1.0em;
  }
}

@media only screen and (min-width: 992px) {
  .showMobile {
    display: none;
  }
}
</style>