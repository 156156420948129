<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-back-button slot="start"></ion-back-button>
        <ion-title>{{ getEnv('APP_NAME') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="entry-parent" :class="randomBack()">
        <div id="content-box">
          <div class="form-box">
            <ion-grid>
              <ion-row class="ion-text-center">
                <ion-col>
                  <img class="logo-login" src="/assets/login/welcome_logo.png">
                </ion-col>
              </ion-row>
            </ion-grid>
            <p style="color:white; text-align:center; text-shadow: 0 0 1px rgba(0, 0, 0, 0.5); font-weight: bold; font-size: 1.2em;">Join {{ getEnv('APP_NAME') }}</p>
                    <span v-if="getEnv('APP_NAME') == 'ChoiceSocial'" style="font-size: 0.8em;"><br />by
                      PikMobile<sup>&reg;</sup></span>
            <ion-button expand="block" @click="signup()">Sign up</ion-button>
            <p style="text-align:center; font-weight: bold; color:white; text-shadow: 0 0 1px rgba(0, 0, 0, 0.5); ">Already have an account?</p>
            <ion-button expand="block" color="light" @click="login()">Log in</ion-button>
            <ion-grid v-show="!isPlatform('hybrid') && !isPlatform('electron')" class="ion-text-center">
              <ion-row>
                <ion-col v-show="getEnv('ITUNES_URL') != '#'" class="ion-padding-vertical">
                  <a :href="getEnv('ITUNES_URL')" target="_blank">
                    <img src="/assets/login/apple_app_store.png" />
                  </a>
                </ion-col>
                <ion-col class="ion-padding-vertical">
                  <a :href="getEnv('PLAYSTORE_URL')" target="_blank">
                    <img alt='Get it on Google Play' src='/assets/login/en_badge_web_generic.png' />
                  </a>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid v-show="isPlatform('hybrid') || isPlatform('electron')">
              <ion-row class="ion-text-center small-link">
                <ion-col>
                  <a :href="getEnv('PRIVACY_PAGE')" target="_blank">
                    {{ $t('navlinks.privacy') }}
                  </a>
                </ion-col>
                <ion-col v-if="getEnv('MOBILE_TERMS_PAGE') != ''">
                  <a :href="getEnv('MOBILE_TERMS_PAGE')" target="_blank">
                    {{ $t('navlinks.mobileterms') }}
                  </a>
                </ion-col>
                <ion-col>
                  <a :href="getEnv('TERMS_PAGE')" target="_blank">
                    {{ $t('navlinks.terms') }}
                  </a>
                </ion-col>
              </ion-row>
              <ion-row class="ion-text-center">
                <ion-col></ion-col>
                <ion-col>
                  <ion-select style="color:white; font-size: 0.8em;" justify="space-between"
                    :label="$t('options.dislang')" :aria-label="$t('options.dislang')"
                    @ionChange="changeLocale($event.detail.value)" :value="this.$root.$i18n.locale" interface="popover">
                    <ion-select-option value="en">English</ion-select-option>
                    <ion-select-option value="pt">Portuguese</ion-select-option>
                    <ion-select-option value="es">Spanish</ion-select-option>
                  </ion-select>
                </ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
      </div>
    </ion-content>
    <FooterContainer v-show="!isPlatform('hybrid') && !isPlatform('electron')" />
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonCol, IonGrid, IonRow, IonButton, isPlatform, IonHeader, IonToolbar, IonBackButton, IonTitle, IonSelect, IonSelectOption, modalController, alertController, loadingController } from '@ionic/vue';
import { defineAsyncComponent } from 'vue'
import SignupModal from '@/components/SignupModal.vue'
import { Browser } from '@capacitor/browser';

import '../theme/signup.css';
import getEnv from '@/utils/env'
const FooterContainer = defineAsyncComponent(() => import('@/components/FooterContainer.vue'))

export default {
  name: "LoginView",
  components: { IonContent, IonPage, IonCol, IonGrid, IonRow, IonButton, FooterContainer, IonHeader, IonToolbar, IonBackButton, IonTitle, IonSelect, IonSelectOption },
  setup() {
    return {
      getEnv,
      isPlatform
    }
  },
  beforeCreate() {
    if (this.$route.query.lang != undefined) {
      this.$root.$i18n.locale = this.$route.query.lang;
    }
  },
  ionViewDidEnter() {
    this.$store.dispatch("header/general", {
      page: this.$t('header.login')
    });
    if(getEnv('APP_NAME') == 'ChoiceSocial'){
      this.backVal = Math.floor(Math.random() * (7 - 1) + 1);
    }
    if(this.$route.path == '/idauth'){
      const code = this.$route.query.code;
      if(code==undefined){
        this.presentErrorAlert('Account not found');
      }else{
        this.fsbnLogin(this.$route.query.code);
      }
    }
    
  },
  data() {
    return {
      backVal: 1,
      authError: ''
    }
  },
  methods: {
    signup() {
      if(getEnv('APP_NAME') == 'FrankSocial'){
        this.fsbnConnect('signup');
      }else{
        this.presentSignup('signup');
        //this.$router.push('/signup');
      }
    },
    login() {
      if(getEnv('APP_NAME') == 'FrankSocial'){
        this.fsbnConnect('login');
      }else{
        this.presentSignup('login');
        //this.$router.push('/user-login');
      }
      
    },
    async openLink(link_url) {
      if (link_url != '') {
        if (isPlatform('hybrid') || isPlatform('electron')) {
          await Browser.open({ url: link_url, presentationStyle: 'popover' });
        }else{
          window.open(link_url, '_blank');
        }
      }
    },
    async fsbnConnect(type) {
      let redirect = 'https://' + window.location.hostname + '/idauth';
      if (isPlatform('hybrid') || isPlatform('electron')) {
        redirect = 'https://' + getEnv('SHARE_HOSTNAME') + '/idauth';
      }
      const client_id = 'ceb0f61a-976b-4b29-a59f-55ec3f600d6f';
      let url_add = '';
      if(type=="signup") url_add = '&goto=signup';
      this.openLink('https://frankspeech.com/OAuth/Start?redirect_uri='+redirect+'&client_id='+client_id+'&response_type=code&state='+type+url_add);
    },
    changeLocale(select) {
      this.$root.$i18n.locale = select;
      return;
    },
    randomBack() {
      return 'background-' + this.backVal;
    },
    async fsbnLogin(code) {
      await this.presentLoading();
      let response = await this.$store.dispatch("auth/idAuthToken", {
        fsbntoken: code
      });

      if(response != null){
        if (response.error != null) {
          // no user was found and speech was unable to authenticate the code (something is wrong)
          this.loadingIndicator.dismiss();
          console.log(response.error, 'error info');
          this.presentErrorAlert(response.error);
        } else if (response.user_account_id != null) { 
          // the speech code was not found so set up a new account
          this.loadingIndicator.dismiss();
          this.presentSignup('signup');
        }else if (response == true) {
          // the speech code was found they are authenticated log them in
          let profileResponse = await this.$store.dispatch("profile/info");
          if (profileResponse) {
            this.$root.$i18n.locale = this.$store.state.profile.locale;
            this.loadingIndicator.dismiss();
            if (this.type == 'modal') {
              this.$emit('update:dismissFlag', true);
            } 
            if (this.$store.state.helpers.loginRedirect != '') {
              var loginRedirect = this.$store.state.helpers.loginRedirect;
              this.$store.commit('helpers/setRedirectLogin', '');
              //window.location = loginRedirect;
              this.$router.replace(loginRedirect);
            } else {
              console.log('go home');
              //console.log(this.$store.state.auth.token, 'token');
              this.$router.replace('/home');
            }
          }
        }
      }
    },
    async presentSignup(type) {
      let title = this.$t('header.login');
      if (type == "signup") {
        title = this.$t('header.signup');
      }
      const modal = await modalController
        .create({
          component: SignupModal,
          cssClass: 'modal-custom-class',
          componentProps: {
            title: title,
            loginType: type,
            source: 'home'
          }
        })
      await modal.present();
    },
    async presentLoading() {
      let message = this.$t('loadmessage.loggingin');
      if (this.resetPass) {
        message = this.$t('loadmessage.passlink');
      }
      this.loadingIndicator = await loadingController
        .create({
          cssClass: 'loading-class',
          message: message
        });
      await this.loadingIndicator.present();
    },
    async presentErrorAlert(error) {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Login Error',
          message: error,
          buttons: [
            {
              text: 'OK',
            },
          ],
        });
      return alert.present();
    },
  }
};
</script>